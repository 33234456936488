<script lang="ts" setup>
import { useUrlStore } from '~/store/url'

const urlStore = useUrlStore()
</script>

<template>
  <main class="relative">
    <slot />
    <div v-if="urlStore.selected.products.size > 0" class="z-5 shadow-8 fixed bottom-0 flex gap-4 w-full justify-center p-2" style="backdrop-filter:blur(8px);">
      <NuxtLink v-if="urlStore.exceptions.collection" :to="`${urlStore.basePdfUrl.collection}?${urlStore.productUrlSearchParams}`" target="_blank" rel="noopener">
        <Button label="Collection" icon="pi pi-file-pdf" class="py-3" :badge="urlStore.selected.products.size.toString()" />
      </NuxtLink>
      <NuxtLink v-if="urlStore.exceptions.technical" :to="`${urlStore.basePdfUrl.technical}?${urlStore.productUrlSearchParams}`" target="_blank" rel="noopener">
        <Button label="Technical" icon="pi pi-file-pdf" class="py-3" :badge="urlStore.selected.products.size.toString()" />
      </NuxtLink>
    </div>
  </main>
</template>
